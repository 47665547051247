
          @import "@/styles/global.scss";
        
.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .top {
    display: flex;
    //overflow: hidden;
    position: relative;
    width: 100%;
    //max-height: rem(190px * $pc_ratio);
    text-align: center;

    @include respond-to('small') {
      //max-height: rem(190px);
    }

    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: white;

      .title {
        font-size: rem(19px * $pc-ratio * $font-ratio);
        font-weight: bold;

        @include respond-to('small') {
          font-size: rem(19px * $font-ratio);
        }
      }

      .desc {
        font-size: rem(10px * $pc-ratio * $font-ratio);

        @include respond-to('small') {
          font-size: rem(10px * $font-ratio);
        }
      }
    }
  }

  .section_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 rem(12px * $pc-ratio);
    justify-content: space-between;
    margin-bottom: rem(13px * $pc-ratio);
    margin-top: rem(22px * $pc-ratio);

    @include respond-to('small') {
      margin: 0 rem(12px);
      margin-bottom: rem(13px);
      margin-top: rem(22px);
    }

    .section_title {
      display: flex;
      align-items: center;
      font-size: rem(12px * $pc-ratio * $font-ratio);
      font-weight: bold;

      @include respond-to('small') {
        font-size: rem(12px * $font-ratio);
      }
    }

    .section_all {
      color: #6e6f72;
      font-size: rem(10px * $pc-ratio * $font-ratio);
      padding: rem(10px * $pc-ratio) 0;

      @include respond-to('small') {
        font-size: rem(10px * $font-ratio);
        padding: rem(10px) 0;
      }
    }
  }

  .festival_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(12px * $pc-ratio);

    @include respond-to('small') {
      margin: 0 rem(12px);
    }
  }
}
