
          @import "@/styles/global.scss";
        
.root {
  .image_swiper {
    //height: rem(300px * $pc-ratio);

    @include respond-to('small') {
      //height: rem(300px);
    }

    .image {
      width: 100%;
    }
  }

  .badge_wrapper {
    display: flex;
    align-items: center;

    .badge {
      font-size: rem(6px * $pc-ratio * $font-ratio);
      border-radius: rem(15px * $pc-ratio);
      background-color: white;
      border: 1px solid black;
      color: black;
      padding: rem(5px *  $pc-ratio) rem(8px *  $pc-ratio);

      &.selected {
        background-color: black;
        color: white;
      }

      @include respond-to('small') {
        font-size: rem(6px * $font-ratio);
        border-radius: rem(15px);
        padding: rem(5px) rem(8px);
      }
    }
  }

  .title_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      flex: 1;
      font-size: rem(14px * $pc-ratio);
      font-weight: bold;

      @include respond-to('small') {
        font-size: rem(14px);
      }
    }
  }

  .info_border_container {
    margin: 0 rem(0px * $pc-ratio) rem(7px * $pc-ratio) rem(0px * $pc-ratio);
    border: 1px solid #cccccc;
    border-radius: rem(4px * $pc-ratio);
    padding: rem(14px * $pc-ratio) rem(12px * $pc-ratio);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-to('small') {
      margin: 0 rem(0px) rem(7px) rem(0px);
      border-radius: rem(4px);
      padding: rem(14px) rem(12px);
    }

    .info_title_wrapper {
      display: flex;
      align-items: center;
      font-size: rem(9px * $pc-ratio * $font-ratio);
      color: black;

      @include respond-to('small') {
        font-size: rem(9px * $font-ratio);
      }
    }

    .info_date {
      font-size: rem(9px * $pc-ratio * $font-ratio);
      color: black;
      margin-top: rem(5px * $pc-ratio);

      @include respond-to('small') {
        font-size: rem(9px * $font-ratio);
        margin-top: rem(5px);
      }
    }

    .info_desc {
      font-size: rem(8px * $pc-ratio * $font-ratio);
      color: #7a7a7a;

      @include respond-to('small') {
        font-size: rem(8px * $font-ratio);
      }
    }

  }

  .icon_gps {
    margin-right: rem(-3px * $pc-ratio);

    @include respond-to('small') {
      margin-right: rem(-3px);
    }
  }

  .section {
    margin: rem(45px * $pc-ratio) 0 0 rem(12px * $pc-ratio);

    @include respond-to('small') {
      margin: rem(45px) 0 0 rem(12px);
    }

    .section_title {
      font-size: rem(14px * $pc-ratio);
      font-weight: bold;
      margin-bottom: rem(17px * $pc-ratio);

      @include respond-to('small') {
        font-size: rem(14px);
        margin-bottom: rem(17px);
      }
    }

    .section_desc {
      font-size: rem(8px * $pc-ratio * $font-ratio);
      color: rgba(0, 0, 0, 0.87);

      @include respond-to('small') {
        font-size: rem(8px * $font-ratio);
      }
    }

    .event_wrapper {
      display: flex;
      overflow-x: auto;
    }

    .timeline {
      height: rem(163px * $pc-ratio);
      width: calc(100% - 30px);

      @include respond-to('small') {
        height: rem(163px);
        width: calc(100% - 24px);
      }
    }

    .avatar_wrapper {
      display: flex;
      overflow-x: auto;

      .avatar_item {
        text-align: center;

        .avatar_image {
          width: rem(70px * $pc-ratio);
          height: rem(70px * $pc-ratio);
          border-radius: 100%;

          @include respond-to('small') {
            width: rem(70px);
            height: rem(70px);
          }
        }

        .avatar_title {
          font-size: rem(8px * $pc-ratio * $font-ratio);
          margin-top: rem(9px * $pc-ratio);
          text-align: center;

          @include respond-to('small') {
            font-size: rem(8px * $font-ratio);
            margin-top: rem(9px);
          }
        }
      }
    }
  }
}
