
          @import "@/styles/global.scss";
        
.root {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .close {
    align-self: flex-end;
  }

  .body {
    background-color: white;
    border-radius: rem(15px * $pc-ratio);
    color: #616161;

    @include respond-to('small') {
      border-radius: rem(15px);
    }

    .title {
      text-align: center;
      font-size: rem(12px * $pc-ratio * $font-ratio);

      @include respond-to('small') {
        font-size: rem(12px * $font-ratio);
      }
    }

    .content {
      height: rem(200px * $pc-ratio);
      font-size: rem(11px * $pc-ratio * $font-ratio);
      overflow-y: auto;

      @include respond-to('small') {
        height: rem(300px);
        font-size: rem(11px * $font-ratio);
      }
    }
  }
}
