
          @import "@/styles/global.scss";
        
.root {
  background-color: transparent;
  display: flex;
  align-items: flex-start;

  .container {
    background-color: white;
    height: 100%;
    flex: 1;
    padding: rem(10px * $pc-ratio) rem(24px * $pc-ratio) 0 rem(24px * $pc-ratio);

    @include respond-to('small') {
      padding: rem(10px) rem(24px) 0 rem(24px);
    }
  }

  .login_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .login_btn {
    display: flex;
    align-items: center;
    height: rem(40px * $pc-ratio);
    color: black;
    font-weight: bold;
    font-size: rem(5px * $pc-ratio * $font-ratio);

    @include respond-to('small') {
      height: rem(40px);
      font-size: rem(5px * $font-ratio);
    }

    .profile_image {
      width: rem(16px * $pc-ratio);
      height: rem(16px * $pc-ratio);
      border-radius: 100%;
      background-color: #d9d9d9;

      @include respond-to('small') {
        width: rem(16px);
        height: rem(16px);
      }
    }
  }

  .menu_wrapper {
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;

    .menu_item {
      display: flex;
      height: rem(24px * $pc-ratio);
      align-items: center;

      @include respond-to('small') {
        height: rem(24px);
      }

      .menu_text {
        font-size: rem(7px * $pc-ratio * $font-ratio);
        color: black;

        @include respond-to('small') {
          font-size: rem(7px * $font-ratio);
        }
      }
    }
  }

  .sns_wrapper {
    display: flex;
    align-items: center;
  }
}
