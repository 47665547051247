
          @import "@/styles/global.scss";
        
.root {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .logo {
    width: rem(233px * $pc-ratio);

    @include respond-to('small') {
      width: rem(233px);
    }
  }

  button {
    width: rem(330px * $pc-ratio);
    @include respond-to('small') {
      width: rem(330px);
    }
  }
}
