
          @import "@/styles/global.scss";
        
.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  vertical-align: top;
  width: 100%;
  padding: 0 0 rem(30px * $pc-ratio);
  font-size: rem(24px * $pc-ratio * $font-ratio);
  font-weight: 500;
  line-height: rem(1.42);
  text-align: left;

  @include respond-to('small') {
    padding: 0 0 rem(30px);
    font-size: rem(24px * $font-ratio);
  }
}
