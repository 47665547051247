
          @import "@/styles/global.scss";
        
.root {
  background-color: #f6f6f6;
  padding:rem(4px * $pc-ratio) rem(8px * $pc-ratio);
  font-size: rem(12px * $pc-ratio * $font-ratio);
  border-radius: rem(4px * $pc-ratio);
  margin-right: rem(4px * $pc-ratio);
  margin-bottom: rem(4px * $pc-ratio);
  color: rgba(0, 0, 0, 0.3);

  @include respond-to('small') {
    font-size: rem(12px * $font-ratio);
  }
}

.dark {
  &.selected {
    color: white;
    background-color: black;
  }
}

.orange {
  color: black;

  &.selected {
    color: white;
    background-color: #ef5f2c;
  }
}

