
          @import "@/styles/global.scss";
        
.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .image_wrapper {
      text-align: center;

      .image {
        text-align: center;
        width: rem(105px * $pc-ratio);
        height: rem(105px * $pc-ratio);
        border-radius: rem(150px * $pc-ratio);
        margin-top: rem(14px * $pc-ratio);
        margin-bottom: rem(28px * $pc-ratio);

        @include respond-to('small') {
          width: rem(105px);
          height: rem(105px);
          border-radius: rem(150px);
          margin-top: rem(14px);
          margin-bottom: rem(25px);
        }
      }
    }

    .content_wrapper {
      flex: 1;
      background-color: #f7f7f8;
      padding: rem(8px * $pc-ratio) rem(15px * $pc-ratio);

      .title {
        font-size: rem(20px * $pc-ratio * $font-ratio);
        font-weight: 600;
        margin-bottom: rem(9px * $pc-ratio);

        @include respond-to('small') {
          font-size: rem(20px * $font-ratio);
          margin-bottom: rem(9px);
        }
      }

      .content {
        overflow-y: auto;
        font-size: rem(16px * $pc-ratio * $font-ratio);
        line-height: rem(24px * $pc-ratio * $font-ratio);

        @include respond-to('small') {
          font-size: rem(16px * $font-ratio);
          line-height: rem(24px * $font-ratio);
        }
      }
    }
  }
}
