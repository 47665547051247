
          @import "@/styles/global.scss";
        
.root {
  .business_info {
    color: #2c2c2c;
    font-size: rem(8px * $pc-ratio * $font-ratio);
    line-height: rem(18px * $pc-ratio);
    text-align: center;
    white-space: pre-wrap;

    @include respond-to('small') {
      font-size: rem(8px * $font-ratio);
      line-height: rem(18px);
    }
  }

  .contact_info {
    color: #2c2c2c;
    font-size: rem(7px * $pc-ratio * $font-ratio);
    text-align: center;

    @include respond-to('small') {
      font-size: rem(7px * $font-ratio);
    }
  }

  .bottom_btn {
    width: rem(24px * $pc-ratio);
    height: rem(24px * $pc-ratio);

    @include respond-to('small') {
      width: rem(24px);
      height: rem(24px);
    }
  }
}
