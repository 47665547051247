
          @import "@/styles/global.scss";
        
@import-sanitize;
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/variable/pretendardvariable-dynamic-subset.css");

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}


html {
    overflow-y: auto;
    overflow-x: hidden;
    font-family: $font-stack;
    font-size: $base-font-size-desktop;

    @include respond-to("small") {
        font-size: $base-font-size-mobile;
    }
}

html, body, .wrap{
    height: 100%;
} 

body {
    background-color: $primary-bg-color;
    color: $base-font-color;
}


a {
    color: inherit;
}

.rc-drawer {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
}

.ant-scrolling-effect {
    width: 100% !important;
}