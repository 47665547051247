
          @import "@/styles/global.scss";
        
.root {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: solid 2px rgba(149, 149, 149, 0.3);
}

textarea {
  display: block;
  width: 100%;
  border: 0;
  //padding: 10px;
  resize: none;
  cursor: text;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  font-size: inherit;
  outline: 0;

  &::placeholder {
    color: #d2d2d2;
  }
}

.add_text {
  display: block;
  margin-top: 5px;

  &.error {
    font-size: rem(15px);
    color: rgb(255, 47, 0);
  }
}
