
          @import "@/styles/global.scss";
        
.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: rem(150px);
  height: rem(50px * $pc-ratio);
  padding: 0;
  border-bottom: solid 2px rgba(128, 128, 128, 1);
  box-sizing: border-box;

  @include respond-to('small') {
    height: rem(50px);
  }

  .select {
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    background-color: transparent;
    font-size: rem(12px * $pc-ratio * $font-ratio);
    padding: 0;
    outline: 0;
    color: #2c2c2c;

    @include respond-to('small') {
      font-size: rem(12px * $font-ratio);
    }

    &::placeholder {
      color: #b3b3b3;
    }

    option { color: #2c2c2c; }
    option:first-child { color: #b3b3b3; }
    &.empty { color: #b3b3b3; }
    /* Hidden placeholder */
    option[disabled]:first-child { display: none; }
  }
}
