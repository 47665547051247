
          @import "@/styles/global.scss";
        
.root {
  display: block;
  padding: 0 0 8px;
  font-size: rem(12px * $pc-ratio * $font-ratio);
  //   font-weight: 500;
  line-height: 1.42;
  text-align: left;
  color: #616161;

  @include respond-to('small') {
    font-size: rem(12px * $font-ratio);
  }
}

.required {
  &::after {
    display: inline-block;
    margin: 0 0 0 2px;
    content: '\002A';
    color: #d75b2f;
  }
}
