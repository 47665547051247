
          @import "@/styles/global.scss";
        
@import './../../styles/mixins/rem';

.root {

  .text {
    text-align: center;
    color: #333333;
    font-size: rem(11px * $pc-ratio * $font-ratio);

    @include respond-to('small') {
      font-size: rem(11px * $font-ratio);
    }
  }

  .button_group {
    height: rem(40px * $pc-ratio);
    display: flex;

    @include respond-to('small') {
      height: rem(40px);
    }

    .button {
      flex: 1;
      border: 1px solid #c7c7c7;
      height: 100%;
      font-size: rem(11px * $pc-ratio * $font-ratio);
      border-radius: rem(8px * $pc-ratio);
      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-to('small') {
        font-size: rem(11px * $font-ratio);
        border-radius: rem(8px);
      }

      &.orange {
        background-color: #ef5f2c;
        border: none;
        color: white;
      }
    }
  }
}
