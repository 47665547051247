
          @import "@/styles/global.scss";
        
.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .search_wrapper {
    display: flex;
    align-items: center;

    .search_input_wrapper {
      border: 1px solid #ef5f2c;
      display: flex;
      align-items: center;
      flex: 1;
      border-radius: rem(4px * $pc-ratio);

      @include respond-to('small') {
        border-radius: rem(4px);
      }

      .search_input {
        flex: 1;
        height: rem(24px * $pc-ratio);
        border: none;

        @include respond-to('small') {
          height: rem(24px);
        }
      }
    }

    .cancel_btn {
      color: #999999;
      font-size: rem(6px * $pc-ratio * $font-ratio);

      @include respond-to('small') {
        font-size: rem(6px * $font-ratio);
      }
    }
  }

  .container {
    flex: 1;

    .title_cell {
      border-bottom: 1px solid #dddddd;
      height: rem(28px * $pc-ratio);
      font-size: rem(7px * $pc-ratio * $font-ratio);
      display: flex;
      align-items: center;
      padding: 0 rem(38px * $pc-ratio);
      white-space: pre-wrap;
      color: #444444;

      @include respond-to('small') {
        height: rem(28px);
        font-size: rem(7px * $font-ratio);
        padding: 0 rem(38px);
      }
    }

    .section {
      margin-top: rem(32px * $pc-ratio);
      padding: 0 rem(12px * $pc-ratio);

      @include respond-to('small') {
        margin-top: rem(32px);
        padding: 0 rem(12px);
      }

      .section_title {
        font-weight: bold;
        color: black;
        font-size: rem(12px * $pc-ratio * $font-ratio);

        @include respond-to('small') {
          font-size: rem(12px * $font-ratio);
        }
      }

      .section_desc {
        color: black;
        font-size: rem(9px * $pc-ratio * $font-ratio);
        margin-top: rem(7px * $pc-ratio);

        @include respond-to('small') {
          font-size: rem(9px * $font-ratio);
          margin-top: rem(7px);
        }
      }
    }

    .festival_wrapper {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
