
          @import "@/styles/global.scss";
        
.root {
  .top {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-height: rem(220px * $pc_ratio);
    text-align: center;

    @include respond-to('small') {
      max-height: rem(220px);
    }

    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: white;
      font-weight: bold;

      .tip1 {
        margin-top: rem(10px * $pc-ratio);
        white-space: pre-wrap;
        font-size: rem(9px * $pc-ratio * $font-ratio);

        @include respond-to('small') {
          font-size: rem(9px * $font-ratio);
        }
      }

      .about {
        position: relative;
        display: block;
        appearance: none;
        width: rem(108px * $pc-ratio);
        height: rem(33px * $pc-ratio);
        line-height: rem(33px * $pc-ratio);
        margin: 0 auto;
        border: none;
        border-radius: rem(20px * $pc-ratio);
        background-color: #ef5f2c;
        font-size: rem(10px * $pc-ratio * $font-ratio);
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        transition: all 0.15s ease;
        text-decoration: none;

        @include respond-to('small') {
          width: rem(108px);
          height: rem(33px);
          line-height: rem(33px);
          border-radius: rem(20px);
          font-size: rem(10px * $font-ratio);
        }
      }
    }
  }

  .tip2 {
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: rem(25px * $pc-ratio * $font-ratio);

    @include respond-to('small') {
      font-size: rem(25px * $font-ratio);
    }

    & > span {
      color: #ef5f2c;
    }
  }

  .step_title {
    color: #ef5f2c;
    font-size: rem(10px * $pc-ratio * $font-ratio);
    padding: rem(15px * $pc-ratio * $font-ratio) rem(28px * $pc-ratio * $font-ratio);
    border: 1px solid #ef5f2c;
    border-radius: rem(32px * $pc-ratio);
    font-weight: bold;

    @include respond-to('small') {
      font-size: rem(10px * $font-ratio);
      padding: rem(10px * $font-ratio) rem(15px * $font-ratio);
      border-radius: rem(32px);
    }
  }

  .step_desc {
    color: #2c2c2c;
    font-size: rem(9px * $pc-ratio * $font-ratio);
    text-align: center;
    white-space: break-spaces;

    @include respond-to('small') {
      font-size: rem(9px * $font-ratio);
    }
  }

  .line {
    background-color: #ef5f2c;
    width: rem(17px * $pc-ratio);
    height: rem(4px * $pc-ratio);

    @include respond-to('small') {
      width: rem(17px);
      height: rem(4px);
    }
  }

  .screenshot_text_wrapper {
    height: rem(212px * $pc-ratio);
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-to('small') {
      height: rem(212px);
    }
  }

  .screenshot_title {
    font-size: rem(13px * $pc-ratio * $font-ratio);
    color: black;
    font-weight: bold;
    white-space: break-spaces;

    @include respond-to('small') {
      font-size: rem(13px * $font-ratio);
    }
  }

  .screenshot_desc {
    font-size: rem(6px * $pc-ratio * $font-ratio);
    color: #2c2c2c;
    white-space: break-spaces;

    @include respond-to('small') {
      font-size: rem(6px * $font-ratio);
    }
  }

  .screenshot_festival {
    float: right;
    width: rem(144px * $pc-ratio);
    height: rem(280px * $pc-ratio);
    margin-right: rem(25px * $pc-ratio);
    margin-top: rem(-198px * $pc-ratio);

    @include respond-to('small') {
      width: rem(144px);
      height: rem(280px);
      margin-right: rem(25px);
      margin-top: rem(-198px);
    }
  }

  .screenshot_meeting {
    width: rem(140px * $pc-ratio);
    height: rem(272px * $pc-ratio);

    @include respond-to('small') {
      width: rem(140px);
      height: rem(272px);
    }
  }

  .screenshot_live_map {
    float: right;
    width: rem(149px * $pc-ratio);
    height: rem(290px * $pc-ratio);
    margin-right: rem(25px * $pc-ratio);
    margin-top: rem(-170px * $pc-ratio);

    @include respond-to('small') {
      width: rem(149px);
      height: rem(290px);
      margin-right: rem(25px);
      margin-top: rem(-170px);
    }
  }

  .screenshot_site_map {
    width: rem(150px * $pc-ratio);
    height: rem(293px * $pc-ratio);

    @include respond-to('small') {
      width: rem(150px);
      height: rem(293px);
    }
  }

  .screenshot_interpreter {
    float: right;
    width: rem(150px * $pc-ratio);
    height: rem(292px * $pc-ratio);
    margin-right: rem(25px * $pc-ratio);
    margin-top: rem(-170px * $pc-ratio);

    @include respond-to('small') {
      width: rem(150px);
      height: rem(292px);
      margin-right: rem(25px);
      margin-top: rem(-190px);
    }
  }

  .clear_both {
    clear: both;
  }
}
