
          @import "@/styles/global.scss";
        
.root {
  .top {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-height: rem(220px * $pc_ratio);
    text-align: center;

    @include respond-to('small') {
      max-height: rem(220px);
    }

    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: white;
      font-weight: bold;

      .title {
        font-size: rem(19px * $pc-ratio * $font-ratio);

        @include respond-to('small') {
          font-size: rem(19px * $font-ratio);
        }
      }

      .about {
        position: relative;
        display: block;
        appearance: none;
        width: rem(108px * $pc-ratio);
        height: rem(33px * $pc-ratio);
        line-height: rem(33px * $pc-ratio);
        margin: 0 auto;
        border: none;
        border-radius: rem(20px * $pc-ratio);
        background-color: #ef5f2c;
        font-size: rem(10px * $pc-ratio * $font-ratio);
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        transition: all 0.15s ease;
        text-decoration: none;

        @include respond-to('small') {
          width: rem(108px);
          height: rem(33px);
          line-height: rem(33px);
          border-radius: rem(20px);
          font-size: rem(10px * $font-ratio);
        }
      }
    }
  }

  .notice_and_upper_menu {
    background-color: #f3f3f3;

    .notice_wrapper {
      height: rem(36px * $pc-ratio);
      border-radius: rem(5px * $pc-ratio);
      background-color: #cfcfcf;

      @include respond-to('small') {
        height: rem(36px);
        border-radius: rem(5px);
      }

      & > div {
        height: 100%;

        & > div {
          height: 100%;
        }
      }

      .notice_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem(36px * $pc-ratio);
        padding: 0 rem(9px * $pc-ratio);
        font-size: rem(6px * $pc-ratio * $font-ratio);

        @include respond-to('small') {
          height: rem(36px);
          padding: 0 rem(9px);
          font-size: rem(6px * $font-ratio);
        }
      }
    }

    .upper_menu_wrapper {
      display: flex;
      align-items: center;

      .upper_menu {
        flex: 1;
        flex-direction: column;
        display: flex;
        align-items: center;

        .upper_menu_icon {
          width: rem(40px * $pc-ratio);
          height: rem(40px * $pc-ratio);
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: rem(5px * $pc-ratio);

          @include respond-to('small') {
            width: rem(40px);
            height: rem(40px);
            border-radius: rem(5px);
          }
        }

        .upper_menu_text {
          font-size: rem(6px * $pc-ratio * $font-ratio);
          font-weight: 500;
          line-height: rem(10px * $pc-ratio);
          letter-spacing: 0;
          text-align: center;
          color: black;

          @include respond-to('small') {
            font-size: rem(6px * $font-ratio);
            line-height: rem(10px);
          }
        }
      }
    }
  }

  .banner_wrapper {
    text-align: center;

    .banner_tip {
      color: black;
      font-size: rem(10px * $pc-ratio * $font-ratio);
      font-weight: bold;

      @include respond-to('small') {
        font-size: rem(10px * $font-ratio);
      }
    }

    .banner_logo {
      width: rem(190px * $pc-ratio);
      height: rem(23px * $pc-ratio);

      @include respond-to('small') {
        width: rem(190px);
        height: rem(23px);
      }
    }

    .youtube_wrapper {
      overflow: hidden;
      display: inline-flex;
      border-radius: 10px;
      max-width: 980px;
      margin: 0 auto;

      & > div {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    //.banner_image {
    //  width: 100%;
    //  height: rem(185px * $pc-ratio);
    //
    //  @include respond-to('small') {
    //    height: rem(185px);
    //  }
    //}

    .banner_swiper {
      height: rem(185px * $pc-ratio);

      @include respond-to('small') {
        height: rem(185px);
      }
    }

    .banner_tip2 {
      color: black;
      font-size: rem(10px * $pc-ratio * $font-ratio);

      @include respond-to('small') {
        font-size: rem(10px * $font-ratio);
      }

      & > span {
        color: #d75b2f;
      }
    }
  }

  .section {
    margin-top: rem(32px * $pc-ratio);
    padding: 0 rem(12px * $pc-ratio);

    @include respond-to('small') {
      margin-top: rem(32px);
      padding: 0 rem(12px);
    }

    .section_title {
      font-weight: bold;
      color: black;
      font-size: rem(12px * $pc-ratio * $font-ratio);

      @include respond-to('small') {
        font-size: rem(12px * $font-ratio);
      }
    }

    .section_desc {
      color: black;
      font-size: rem(9px * $pc-ratio * $font-ratio);
      margin-top: rem(7px * $pc-ratio);

      @include respond-to('small') {
        font-size: rem(9px * $font-ratio);
        margin-top: rem(7px);
      }
    }
  }

  .festival_type_wrapper {
    display: flex;
  }

  .festival_wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .selectable_badge {
    border-radius: rem(20px * $pc-ratio);
    font-size: rem(6px * $pc-ratio * $font-ratio);

    @include respond-to('small') {
      border-radius: rem(20px);
      font-size: rem(6px * $font-ratio);
    }
  }

  .more_btn {
    border-radius: rem(4px * $pc-ratio);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
    height: rem(48px * $pc-ratio);
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: rem(15px * $pc-ratio);

    @include respond-to('small') {
      border-radius: rem(4px);
      height: rem(24px);
      margin-top: rem(15px);
    }

    .more_btn_text {
      font-size: rem(6px * $pc-ratio * $font-ratio);
      font-weight: 500;
      line-height: rem(10px * $pc-ratio * $font-ratio);
      letter-spacing: 0;
      text-align: center;
      color: #2c2c2c;

      @include respond-to('small') {
        font-size: rem(6px * $font-ratio);
        line-height: rem(10px * $font-ratio);
      }
    }
  }

  .download_wrapper {
    background-color: #d75b2f;
    height: rem(181px * $pc-ratio);
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to('small'){
      height: rem(181px);
    }
    
    .download_image {
      width: rem(67px * $pc-ratio);
      height: rem(138px * $pc-ratio);

      @include respond-to('small'){
        width: rem(67px);
        height: rem(138px);
      }
    }

    .download_now {
      font-weight: bold;
      color: white;
      font-size: rem(12px * $pc-ratio * $font-ratio);

      @include respond-to('small') {
        font-size: rem(12px * $font-ratio);
      }
    }

    .download_tip {
      color: white;
      font-size: rem(6px * $pc-ratio * $font-ratio);
      line-height: rem(12px * $pc-ratio);
      white-space: pre-wrap;

      @include respond-to('small') {
        font-size: rem(6px * $font-ratio);
        line-height: rem(12px);
      }
    }

    .download_btn {
      display: flex;
      width: rem(84px * $pc-ratio);
      height: rem(24px * $pc-ratio);

      @include respond-to('small') {
        width: rem(84px);
        height: rem(24px);
      }
    }
  }
}
