@import 'variables';
@import 'mixins';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: $container-max-width;
  margin: auto;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}

.modal.show {
  display: block;
}

.modal_body {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 450px;
  height: 400px;

  padding: 40px;

  text-align: center;

  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);

  transform: translateX(-50%) translateY(-50%);
  z-index: 99999;

  @include respond-to('small') {
    width: 350px;
    height: 300px;
    padding: 20px;
  }

  .modalImgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: pink;
    padding: 0 20px;
  }

  .modalText {
    font-weight: 700;
    font-size: 24px;
    line-height: 155%;
    /* or 56px */
    text-align: center;
    margin-bottom: 30px;
    word-break: keep-all;

    @include respond-to('small') {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .close {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    img {
      width: 30px;
      height: 30px;
      cursor: pointer;

      @include respond-to('small') {
        width: 25px;
      }
    }
  }

  .icon {
    width: 150px;
    margin-right: 20px;

    @include respond-to('small') {
      width: 100px;
      margin-right: 0px;
    }
  }

  .button_list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    text-align: left;

    @include respond-to('small') {
      margin: 20px;
    }
  }

  .button_item {
    display: block;
    margin: 0 10px;
  }

  .appstore,
  .googleplay {
    display: block;
    width: rem(150px);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    font-size: 0;
    margin-bottom: 15px;

    @include respond-to('small') {
      width: 100px;
    }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-d-column {
  flex-direction: column;
}

$value: 0;
@while ($value <= 10) {
  .opacity-#{$value} {
    opacity: ($value / 10) !important;
  }

  $value: $value + 1;
}

$value: 0;
@while ($value <= 100) {
  .m-#{$value} {
    margin: rem($value * $pc-ratio * 1px) !important;
  }
  .ml-#{$value} {
    margin-left: rem($value * $pc-ratio * 1px) !important;
  }
  .mr-#{$value} {
    margin-right: rem($value *$pc-ratio *  1px) !important;
  }
  .mt-#{$value} {
    margin-top: rem($value * $pc-ratio * 1px) !important;
  }
  .mb-#{$value} {
    margin-bottom: rem($value * $pc-ratio * 1px) !important;
  }
  .mv-#{$value} {
    margin-top: rem($value * $pc-ratio * 1px) !important;
    margin-bottom: rem($value * $pc-ratio * 1px) !important;
  }
  .mh-#{$value} {
    margin-left: rem($value * $pc-ratio * 1px) !important;
    margin-right: rem($value * $pc-ratio * 1px) !important;
  }
  .p-#{$value} {
    padding: rem($value * $pc-ratio * 1px) !important;
  }
  .pl-#{$value} {
    padding-left: rem($value * $pc-ratio * 1px) !important;
  }
  .pr-#{$value} {
    padding-right: rem($value * $pc-ratio * 1px) !important;
  }
  .pt-#{$value} {
    padding-top: rem($value * $pc-ratio * 1px) !important;
  }
  .pb-#{$value} {
    padding-bottom: rem($value * $pc-ratio * 1px) !important;
  }
  .pv-#{$value} {
    padding-top: rem($value * $pc-ratio * 1px) !important;
    padding-bottom: rem($value * $pc-ratio * 1px) !important;
  }
  .ph-#{$value} {
    padding-left: rem($value * $pc-ratio * 1px) !important;
    padding-right: rem($value * $pc-ratio * 1px) !important;
  }
  .br-#{$value} {
    border-radius: rem($value * $pc-ratio * 1px) !important;
  }
  .t-#{$value} {
    top: rem($value * $pc-ratio * 1px) !important;
  }
  .b-#{$value} {
    bottom: rem($value * $pc-ratio * 1px) !important;
  }
  .l-#{$value} {
    left: rem($value * $pc-ratio * 1px) !important;
  }
  .r-#{$value} {
    right: rem($value * $pc-ratio * 1px) !important;
  }
  .w-#{$value} {
    width: rem($value * $pc-ratio * 1px) !important;
  }
  .h-#{$value} {
    height: rem($value * $pc-ratio * 1px) !important;
  }

  @include respond-to('small') {
    .m-#{$value} {
      margin: rem($value * 1px) !important;
    }
    .ml-#{$value} {
      margin-left: rem($value * 1px) !important;
    }
    .mr-#{$value} {
      margin-right: rem($value * 1px) !important;
    }
    .mt-#{$value} {
      margin-top: rem($value * 1px) !important;
    }
    .mb-#{$value} {
      margin-bottom: rem($value * 1px) !important;
    }
    .mv-#{$value} {
      margin-top: rem($value * 1px) !important;
      margin-bottom: rem($value * 1px) !important;
    }
    .mh-#{$value} {
      margin-left: rem($value * 1px) !important;
      margin-right: rem($value * 1px) !important;
    }
    .p-#{$value} {
      padding: rem($value * 1px) !important;
    }
    .pl-#{$value} {
      padding-left: rem($value * 1px) !important;
    }
    .pr-#{$value} {
      padding-right: rem($value * 1px) !important;
    }
    .pt-#{$value} {
      padding-top: rem($value * 1px) !important;
    }
    .pb-#{$value} {
      padding-bottom: rem($value * 1px) !important;
    }
    .pv-#{$value} {
      padding-top: rem($value * 1px) !important;
      padding-bottom: rem($value * 1px) !important;
    }
    .ph-#{$value} {
      padding-left: rem($value * 1px) !important;
      padding-right: rem($value * 1px) !important;
    }
    .br-#{$value} {
      border-radius: rem($value * 1px) !important;
    }
    .t-#{$value} {
      top: rem($value * 1px) !important;
    }
    .b-#{$value} {
      bottom: rem($value * 1px) !important;
    }
    .l-#{$value} {
      left: rem($value * 1px) !important;
    }
    .r-#{$value} {
      right: rem($value * 1px) !important;
    }
    .w-#{$value} {
      width: rem($value * 1px) !important;
    }
    .h-#{$value} {
      height: rem($value * 1px) !important;
    }
  }

  $value: $value + 1;
}

$value: 8;
@while ($value <= 36) {
  .fs-#{$value} {
    font-size: rem($value * $pc-ratio * 1px * $font-ratio) !important;
  }
  .lh-#{$value} {
    line-height: rem($value * $pc-ratio * 1px) !important;
  }

  @include respond-to('small') {
    .fs-#{$value} {
      font-size: rem($value * 1px * $font-ratio) !important;
    }
    .lh-#{$value} {
      line-height: rem($value * 1px) !important;
    }
  }

  $value: $value + 1;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.page-content {
  width: $container-max-width;
  margin: auto;
  position: relative;

  @include respond-to('small') {
    width: 100%;
  }
}

.split {
  width: 100%;
  height: rem(1px);
  background-color: #e5e5e5;
}


.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  display: none;
}

.react-datepicker__navigation--years::before {
  border-color: black;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}


.react-datepicker__navigation--years-upcoming {
  transform: rotate(-45deg);
  left: 12px;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  transform: rotate(135deg);
  right: 12px;
  top: 0;
}