
          @import "@/styles/global.scss";
        
.root {
  display: flex;
  flex-direction: column;

  .tip {
    color: black;
    font-size: rem(13px * $pc-ratio * $font-ratio);

    @include respond-to('small') {
      font-size: rem(13px* $font-ratio);
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .badge_wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .gender {
    width: rem(78px);
    height: rem(78px);
  }
}
