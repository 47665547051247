
          @import "@/styles/global.scss";
        
@import '../../styles/variables';

$radius : rem(5px);

.root {
  height: rem(53px * $pc-ratio);
  font-size: rem(13px * $pc-ratio * $font-ratio);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @include respond-to('small') {
    height: rem(53px);
    font-size: rem(13px * $font-ratio);
  }
}

.text {
  padding: rem(10px);
  background-color: transparent;
  color: #000;
}

.contained {
  padding: rem(20px);
  font-size: rem(24px);
  font-weight: bold;
}

.primary {
  padding: rem(20px);
  border-radius: rem(8px);
  background: #d75b2f;
  border-radius: 200px;
  color: #fff;
}

.yellow {
  background-color: #FEE500;
  color: white;
  border-radius: $radius;
}

.green {
  background-color: #03C75A;
  color: white;
  border-radius: $radius;
}

.grey {
  background-color: #484649;
  color: white;
  border-radius: $radius;
}

.disable {
  background-color: #c7c7c7;
  color: white;
  border-radius: $radius;
}

.orange {
  background-color: #ef5f2c;
  color: white;
  border-radius: $radius;
}

.grey-outline {
  background-color: white;
  border: rem(1px) solid #c7c7c7;
  color: #1c1b1f;
  border-radius: $radius;
}

.full_width {
  width: 100%;
}
