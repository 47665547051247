
          @import "@/styles/global.scss";
        
.root {
  .cell_container {
    height: rem(29px * $pc-ratio);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin: 0 rem(12px * $pc-ratio);
    justify-content: space-between;
    display: flex;
    align-items: center;

    @include respond-to('small') {
      height: rem(29px);
      margin: 0 rem(12px);
    }

    .menu_text {
      flex: 1;
      font-size: rem(6px * $pc-ratio * $font-ratio);
      font-weight: bold;
      font-style: normal;
      line-height: rem(10px * $pc-ratio * $font-ratio);
      letter-spacing: 0;
      text-align: left;
      color: #2c2c2c;

      @include respond-to('small') {
        font-size: rem(6px * $font-ratio);
        line-height: rem(10px * $font-ratio);
      }
    }
  }

  .contents {
    font-size: rem(5px * $pc-ratio * $font-ratio);
    font-weight: bold;
    font-style: normal;
    line-height: rem(11px * $pc-ratio * $font-ratio);
    letter-spacing: 0;
    text-align: left;
    color: #2c2c2c;

    @include respond-to('small') {
      font-size: rem(5px * $font-ratio);
      line-height: rem(11px * $font-ratio);
    }
  }

  .rotate_icon {
    transform: rotate(180deg);
  }
}
