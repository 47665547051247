
          @import "@/styles/global.scss";
        
.root {
  background-color: transparent;
  width: 100%;
  height: rem(40px * $pc-ratio);
  z-index: 90;
  padding-top: 0;
  border-bottom-width: 0;
  border-color: #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to('small') {
    height: rem(40px);
  }

  .title_wrapper {
    align-items: center;
    justify-content: center;
    margin-left: rem(18px * $pc-ratio);

    .title {
      letter-spacing: -0.5;
      font-size: rem(8px * $pc-ratio * $font-ratio);
      font-weight: bold;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include respond-to('small') {
        font-size: rem(8px * $font-ratio);
      }
    }
  }

  .logo {
    object-fit: contain;
    width: rem(97px * $pc-ratio);
    height: rem(12px * $pc-ratio);
    padding-left: rem(12px * $pc-ratio);

    @include respond-to('small') {
      width: rem(97px);
      height: rem(12px);
      padding-left: rem(12px);
    }
  }

  .back_wrapper {
    align-items: flex-start;
  }

  .center_container {
    flex: 5;
    align-items: center;
    justify-content: center;
  }

  .right_container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .right_container2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
