
          @import "@/styles/global.scss";
        
.root {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: rem(50px * $pc-ratio);
  border-bottom: solid 2px rgba(128, 128, 128, 1);
  box-sizing: border-box;

  @include respond-to('small') {
    height: rem(50px);
  }

  input[type='text'],
  [type='tel'], [type='number'] {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    font-size: rem(12px * $pc-ratio * $font-ratio);
    outline: 0;
    color: #2c2c2c;

    @include respond-to('small') {
      font-size: rem(12px * $font-ratio);
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #b3b3b3;
      opacity: 1;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #b3b3b3;
      opacity: 1;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #b3b3b3;
      opacity: 1;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #b3b3b3;
      opacity: 1;
    }
  }
}

.add_text {
  display: block;
  margin-top: 5px;

  &.error {
    font-size: rem(15px);
    color: rgb(255, 47, 0);
  }
}
