
          @import "@/styles/global.scss";
        
.root {
  display: flex;
  align-items: center;

  .step_bg {
    background-color: #e5e5e5;
    height: 2px;
    position: relative;
    flex: 1;

    .current_step {
      background-color: #000000;
      height: 2px;
    }
  }

  span {
    color: #797979;
    text-align: right;
    font-size: rem(10px * $pc-ratio * $font-ratio);

    @include respond-to('small') {
      font-size: rem(10px * $font-ratio);
    }
  }
}
