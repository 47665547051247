
          @import "@/styles/global.scss";
        
.root {
  position: relative;
  display: inline-flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  min-width: 10px;
  min-height: 10px;

  &.disabled {
    opacity: 0.6;
  }
}

.search {
  background-image: url(../../assets/images/icon_search@2x.png);

  &.dark {
    //background-image: url(../../assets/images/icon_search_dark@2x.png);
  }
}

//.not_found_image {
//  background-image: url(../../assets/images/not_found_image.png);
//}

.icon_sns1 {
  background-image: url(../../assets/images/icon_sns1@2x.png);
}

.icon_sns2 {
  background-image: url(../../assets/images/icon_sns2@2x.png);
}

.icon_sns3 {
  background-image: url(../../assets/images/icon_sns3@2x.png);
}

.icon_sns4 {
  background-image: url(../../assets/images/icon_sns4@2x.png);
}

.icon_back_arrow {
  background-image: url(../../assets/images/icon_back_arrow@2x.png);
}

.check_all_off {
  background-image: url(../../assets/images/check_all_off@2x.png);
}

.check_all_on {
  background-image: url(../../assets/images/check_all_on@2x.png);
}

.check_off {
  background-image: url(../../assets/images/check_off@2x.png);
}

.check_on {
  background-image: url(../../assets/images/check_on@2x.png);
}

.icon_close_white {
  background-image: url(../../assets/images/icon_close_white@2x.png);
}

.icon_close_black {
  background-image: url(../../assets/images/icon_close_black@2x.png);
}

.icon_option {
  background-image: url(../../assets/images/icon_option@2x.png);
}

.icon_arrow_right {
  background-image: url(../../assets/images/icon_arrow_right@2x.png);
}

.icon_category_1 {
  background-image: url(../../assets/images/icon_category_1@2x.png);
}

.icon_category_2 {
  background-image: url(../../assets/images/icon_category_2@2x.png);
}

.icon_category_3 {
  background-image: url(../../assets/images/icon_category_3@2x.png);
}

.icon_category_4 {
  background-image: url(../../assets/images/icon_category_4@2x.png);
}

.icon_heart_on {
  background-image: url(../../assets/images/icon_heart_on@2x.png);
}

.icon_heart_off {
  background-image: url(../../assets/images/icon_heart_off@2x.png);
}

.icon_onfriends {
  background-image: url(../../assets/images/icon_onfriends@3x.png);
}

.icon_festival {
  background-image: url(../../assets/images/icon_festival@2x.png);
}

.icon_event {
  background-image: url(../../assets/images/icon_event@2x.png);
}

.icon_inquiry {
  background-image: url(../../assets/images/icon_inquiry@2x.png);
}

.icon_notice {
  background-image: url(../../assets/images/icon_notice@2x.png);
}

.icon_f {
  background-image: url(../../assets/images/icon_f@2x.png);
}

.icon_video {
  background-image: url(../../assets/images/icon_video@2x.png);
}

.icon_input_close {
  background-image: url(../../assets/images/icon_input_close@2x.png);
}

.icon_search_orange {
  background-image: url(../../assets/images/icon_search_orrange@3x.png);
}

.icon_back_white {
  background-image: url(../../assets/images/icon_back_white@2x.png);
}

.icon_category_music {
  background-image: url(../../assets/images/icon_category_music@2x.png);
}

.icon_share {
  background-image: url(../../assets/images/test_share@3x.png);
}

.icon_schedule {
  background-image: url(../../assets/images/icon_schedule@2x.png);
}

.icon_location {
  background-image: url(../../assets/images/icon_location@2x.png);
}

.icon_dropdown {
  background-image: url(../../assets/images/icon_dropdown@2x.png);
}

.icon_down_arrow {
  background-image: url(../../assets/images/icon_down_arrow@2x.png);
}

.icon_down_arrow_2 {
  background-image: url(../../assets/images/icon_down_arrow_2@2x.png);
}