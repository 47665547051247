
          @import "@/styles/global.scss";
        
@import './../../styles/mixins/rem';

.root {

  .text {
    text-align: center;
    color: black;
    font-size: rem(14px * $pc-ratio * $font-ratio);
    font-weight: bold;
    white-space: break-spaces;

    @include respond-to('small') {
      font-size: rem(14px * $font-ratio);
    }
  }

  .download_btn {
    display: flex;
    width: rem(100px * $pc-ratio);
    height: rem(30px * $pc-ratio);

    @include respond-to('small') {
      width: rem(100px);
      height: rem(30px);
    }
  }

  .button_group {
    display: flex;
    justify-content: space-between;

    .on_button {
      background-color: #ef5f2c;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(30px * $pc-ratio * $font-ratio);
      font-weight: bold;
      border-radius: rem(10px * $pc-ratio);

      @include respond-to('small') {
        font-size: rem(30px * $font-ratio);
        border-radius: rem(10px);
      }
    }

    .button {
      border: 1px solid #c7c7c7;
      font-size: rem(11px * $pc-ratio * $font-ratio);
      border-radius: rem(8px * $pc-ratio);
      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-to('small') {
        font-size: rem(11px * $font-ratio);
        border-radius: rem(8px);
      }

      &.orange {
        background-color: #ef5f2c;
        border: none;
        color: white;
      }
    }
  }
}
