
          @import "@/styles/global.scss";
        
.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .top {
    display: flex;
    //overflow: hidden;
    position: relative;
    width: 100%;
    //max-height: rem(190px * $pc_ratio);
    text-align: center;

    @include respond-to('small') {
      //max-height: rem(190px);
    }

    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: white;

      .title {
        font-size: rem(19px * $pc-ratio * $font-ratio);
        font-weight: bold;

        @include respond-to('small') {
          font-size: rem(19px * $font-ratio);
        }
      }

      .desc {
        font-size: rem(10px * $pc-ratio * $font-ratio);

        @include respond-to('small') {
          font-size: rem(10px * $font-ratio);
        }
      }
    }
  }

  .container {
    flex: 1;
    //overflow-y: auto;

    .form_container {
      margin-top: rem(30px * $pc-ratio);

      @include respond-to('small') {
        margin-top: rem(30px);
      }

      .label {
        font-size: rem(10px * $pc-ratio * $font-ratio);
        font-weight: bold;
        line-height: rem(17px * $pc-ratio * $font-ratio);
        letter-spacing: 0;
        text-align: left;
        color: #616161;
        display: flex;

        .required {
          color: #d75b2f;
          margin-left: rem(4px * $pc-ratio);

          @include respond-to('small') {
            margin-left: rem(4px);
          }
        }

        @include respond-to('small') {
          font-size: rem(10px * $font-ratio);
          line-height: rem(17px * $font-ratio);
        }
      }

      .form_control {
        border: 1px solid #dfdfdf;
        width: 100%;
        min-height: rem(40px * $pc-ratio);
        border-radius: rem(4px * $pc-ratio);
        padding: 0 rem(18px * $pc-ratio);
        margin-top: rem(4px * $pc-ratio);
        height: auto;

        @include respond-to('small') {
          min-height: rem(40px);
          border-radius: rem(4px);
          padding: 0 rem(18px);
          margin-top: rem(4px);
        }
      }
    }

    .button_wrapper {
      display: flex;
      justify-content: center;

      .button {
        height: rem(40px * $pc-ratio);
        font-weight: bold;
        line-height: rem(40px * $pc-ratio);
        background-color: #d75b2f;
        text-align: center;
        border-radius: rem(20px * $pc-ratio);
        color: white;
        font-size: rem(9px * $pc-ratio * $font-ratio);
        width: rem(164px * $pc-ratio);

        @include respond-to('small') {
          height: rem(40px);
          font-size: rem(9px * $font-ratio);
          width: rem(164px);
          border-radius: rem(20px);
          line-height: rem(40px);
        }
      }
    }
  }
}
