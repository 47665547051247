
          @import "@/styles/global.scss";
        
.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: rem(14px * $pc-ratio * $font-ratio);

  @include respond-to('small') {
    font-size: rem(14px * $font-ratio);
  }

  .icon_all {
    width: 16px;
    margin-right: 10px;
  }
}

