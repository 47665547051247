
          @import "@/styles/global.scss";
        
.root {
  position: relative;

  .image_background {
    aspect-ratio: 1.34;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: rem(6.5px * $pc-ratio);

    @include respond-to('small') {
      border-radius: rem(6.5px);
    }
  }

  .image_wrapper {
    aspect-ratio: 1.34;
    border-radius: rem(6.5px * $pc-ratio);

    @include respond-to('small') {
      border-radius: rem(6.5px);
    }

    img {
      border-radius: rem(6.5px * $pc-ratio);

      @include respond-to('small') {
        border-radius: rem(6.5px);
      }
    }
  }

  .outer_info_container {
    elevation: rem(9px);
    z-index: 999;
    margin: rem(6px * $pc-ratio) 0;

    @include respond-to('small') {
      margin: rem(6px) 0;
    }

    .outer_title {
      font-size: rem(7px * $pc-ratio * $font-ratio);
      font-weight: 600;
      font-style: normal;
      line-height: rem(10px * $pc-ratio * $font-ratio);
      letter-spacing: 0;
      text-align: left;
      color: #000000;
      margin-bottom: rem(4px * $pc-ratio);
      z-index: 999;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @include respond-to('small') {
        font-size: rem(7px * $font-ratio);
        line-height: rem(10px * $font-ratio);
        margin-bottom: rem(4px);
      }
    }

    .outer_desc {
      font-size: rem(6px * $pc-ratio * $font-ratio);
      line-height: rem(10x * $pc-ratio * $font-ratio);
      letter-spacing: 0;
      text-align: left;
      color: rgba(0,0,0,0.6);
      z-index: 999;
      opacity: 1;

      @include respond-to('small') {
        font-size: rem(6px * $font-ratio);
        line-height: rem(10px * $font-ratio);
      }
    }
  }

  .inner_info_container {
    position: absolute;
    bottom: rem(5px * $pc-ratio);
    left: 0;
    z-index: 999;

    @include respond-to('small') {
      bottom: rem(5px)
    }

    .inner_title {
      font-size: rem(14.6px * $pc-ratio * $font-ratio);
      font-weight: 800;
      font-style: normal;
      line-height: rem(19.5px * $pc-ratio * $font-ratio);
      letter-spacing: 0;
      text-align: left;
      color: #ffffff;
      margin: 0 rem(13px * $pc-ratio) rem(6px * $pc-ratio) rem(13px * $pc-ratio);
      z-index: 999;
      flex: 1;

      @include respond-to('small') {
        font-size: rem(14.6px * $font-ratio);
        line-height: rem(19.5px * $font-ratio);
        margin: 0 rem(13px) rem(6px) rem(13px);
      }
    }

    .inner_desc {
      font-size: rem(12px * $pc-ratio * $font-ratio);
      line-height: rem(16px * $pc-ratio * $font-ratio);
      letter-spacing: 0;
      text-align: left;
      color: rgba(0,0,0,0.6);
      z-index: 999;
      opacity: 1;
    }
  }

  .badge {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    width: rem(37px * $pc-ratio);
    height: rem(17px * $pc-ratio);
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: rem(6px * $pc-ratio) rem(6px * $pc-ratio) 0 0;
    font-size: rem(6px * $pc-ratio * $font-ratio);
    border-radius: rem(3px * $pc-ratio);

    &.plus {
      background-color: #2d51e2;
    }

    &.minus {
      background-color: #fb4e4e;
    }

    @include respond-to('small') {
      width: rem(37px);
      height: rem(17px);
      font-size: rem(6px * $font-ratio);
      margin: rem(6px) rem(6px) 0 0;
      border-radius: rem(3px);
    }
  }
}
