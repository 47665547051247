@import "mixins";
$font-stack: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
  'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic',
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
$primary-font-color: #333;
$primary-bg-color: #fff;
$primary-sub-bg-color: #f7f7f8;
$base-font-color: rgba(#000, 0.8);
$base-font-size-desktop: 16px;
$base-font-size-mobile: 13px;

$container-max-width: 1320px;

//$mobile-ratio: 0.7;
$pc-ratio: 1.5;
$font-ratio: 1.5;
