
          @import "@/styles/global.scss";
        
.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .warning_msg {
    color: #fb4e4e;
    font-size: rem(11px * $pc-ratio * $font-ratio);

    @include respond-to('small') {
      font-size: rem(11px * $font-ratio);
    }
  }

  .item {
    display: flex;
    align-items: center;

    & > div {
      flex: 1;
    }

    span {
      color: #666666;
      font-size: rem(13px * $pc-ratio * $font-ratio);

      @include respond-to('small') {
        font-size: rem(13px * $font-ratio);
      }
    }
  }
}
